import React, { useEffect } from 'react';
import { Routes, Route, useNavigationType, useLocation } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import BillInput from './pages/BillInput';
import Login from './pages/Login';
import LandingPageSharePeople from './pages/LandingPageSharePeople';
import OrderForm from './components/OrderForm';
import ResultPage from './components/ResultPage';
import SelectionPage from './components/SelectionPage';


const App: React.FC = () => {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = '';
    let metaDescription = '';

    switch (pathname) {
      case '/bill-input':
        title = 'Bill Input';
        metaDescription = 'Input your bill details here.';
        break;
      case '/login':
        title = 'Login';
        metaDescription = 'Login to your account.';
        break;
      case '/landing-page-share-people':
        title = 'Share Page';
        metaDescription = 'Share with people.';
        break;
      case '/':
        title = 'Splitbill.io';
        metaDescription = 'Fill out your order details.';
        break;
      case '/result/:requestId': // Add case for the result page
        title = 'Result Page';
        metaDescription = 'View the result of your order.';
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector('head > meta[name="description"]');
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/order-form" element={<OrderForm />} />
      <Route path="/" element={<SelectionPage />} />
      <Route path="/bill-input" element={<BillInput />} />
      <Route path="/login" element={<Login />} />
      <Route path="/landing-page-share-people" element={<LandingPageSharePeople />} />
      <Route path="/result/:requestId" element={<ResultPage />} /> {/* Add the route for the result page */}
    </Routes>
  );
}

export default App;
