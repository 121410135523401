import { FunctionComponent, useMemo, type CSSProperties } from "react";

export type FrameComponentType = {
  fluentgroupList20Filled?: string;
  group?: string;

  /** Style props */
  propGap?: CSSProperties["gap"];
};

const FrameComponent: FunctionComponent<FrameComponentType> = ({
  fluentgroupList20Filled,
  group,
  propGap,
}) => {
  const frameDivStyle: CSSProperties = useMemo(() => {
    return {
      gap: propGap,
    };
  }, [propGap]);

  return (
    <div className="self-stretch flex flex-row items-start justify-start py-0 px-[11px] text-center text-base text-white font-inter">
      <div
        className="flex-1 flex flex-col items-start justify-start gap-[3px]"
        style={frameDivStyle}
      >
        <div className="flex flex-row items-start justify-start py-0 px-6">
          <img
            className="h-[50px] w-[50px] relative overflow-hidden shrink-0 z-[4]"
            loading="lazy"
            alt=""
            src={fluentgroupList20Filled}
          />
        </div>
        <b className="self-stretch h-[47px] relative leading-[150%] flex items-center justify-center shrink-0 z-[4]">
          {group}
        </b>
      </div>
    </div>
  );
};

export default FrameComponent;
