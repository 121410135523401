import { FunctionComponent } from "react";
import FrameComponent from "./FrameComponent";

const Frame: FunctionComponent = () => {
  return (
    <div className="w-[120px] bg-gray-100 overflow-hidden shrink-0 flex flex-col items-start justify-start pt-[5px] px-0 pb-[449px] box-border gap-[33px] z-[3] text-center text-base text-white font-inter mq750:hidden mq450:pb-[190px] mq450:box-border mq1050:pt-5 mq1050:pb-[292px] mq1050:box-border">
      <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-[9px]">
        <div className="self-stretch flex flex-row items-start justify-start py-0 px-[22px]">
          <img
            className="h-[107px] flex-1 relative max-w-full overflow-hidden object-cover"
            loading="lazy"
            alt=""
            src="/logo-transparan-1@2x.png"
          />
        </div>
        <img
          className="self-stretch h-px relative max-w-full overflow-hidden shrink-0 object-contain z-[4]"
          loading="lazy"
          alt=""
        />
      </div>
      <FrameComponent
        fluentgroupList20Filled="/fluentgrouplist20filled.svg"
        group="Group"
      />
      <FrameComponent
        fluentgroupList20Filled="/fluentmdl2contactlist.svg"
        group="Contact"
        propGap="12px"
      />
      <div className="self-stretch flex flex-row items-start justify-start py-0 px-[11px]">
        <div className="h-[90px] flex-1 relative">
          <b className="absolute top-[43px] left-[0px] leading-[150%] flex items-center justify-center w-[98px] h-[47px] z-[4]">
            Payment
          </b>
          <img
            className="absolute top-[0px] left-[24px] w-[50px] h-[50px] overflow-hidden z-[4]"
            loading="lazy"
            alt=""
            src="/streamlinepayment10.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default Frame;
