import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const TextWrapper: FunctionComponent = () => {
  const navigate = useNavigate();

  const onCtaButtonClick = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  return (
    <div className="flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[46px] box-border gap-[35px] min-w-[762px] max-w-[816px] text-center text-mini text-white font-inter mq850:pb-[30px] mq850:box-border mq850:max-w-full mq450:gap-[17px]">
      <div className="self-stretch flex flex-col items-start justify-start gap-[35px] max-w-full mq450:gap-[17px]">
        <h1 className="m-0 self-stretch relative text-17xl tracking-[-0.04em] font-black font-inherit text-whitesmoke-200 mq850:text-10xl mq450:text-3xl">
          Office Gang
        </h1>
        <div className="self-stretch flex flex-row items-start justify-center py-0 px-5">
          <div className="w-[212px] relative tracking-[-0.04em] font-black inline-block shrink-0">
            Who are you?
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-left text-black">
          <div className="w-[371px] rounded-mini bg-gainsboro flex flex-row items-start justify-between pt-[7px] pb-1 pr-[10.5px] pl-[18.5px] box-border max-w-full gap-[20px]">
            <div className="h-[34px] w-[371px] relative rounded-mini bg-gainsboro hidden max-w-full" />
            <div className="w-[212px] relative tracking-[-0.04em] font-black inline-block shrink-0 z-[1]">
              Reja
            </div>
            <div className="flex flex-col items-start justify-start pt-[7px] px-0 pb-0 box-border w-[15px] h-[15px]">
              <img
                className="w-[15px] h-2 relative z-[1]"
                alt=""
                src="/vector-1.svg"
              />
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-left text-xs">
          <div className="w-[562px] bg-darkslategray-300 box-border overflow-hidden shrink-0 flex flex-row items-start justify-center max-w-full [row-gap:20px] border-[1px] border-solid border-white mq850:flex-wrap">
            <div className="w-[137px] bg-darkslategray-300 box-border overflow-hidden shrink-0 flex flex-col items-start justify-start pt-0 px-0 pb-7 relative border-[1px] border-solid border-white">
              <div className="w-[235px] bg-darkorange box-border overflow-hidden flex flex-row items-start justify-start border-[1px] border-solid border-white">
                <div className="relative [text-decoration:underline] inline-block min-w-[34px]">
                  Name
                </div>
              </div>
              <div className="w-[235px] bg-darkslategray-300 overflow-hidden flex flex-row items-start justify-start">
                <div className="relative inline-block min-w-[25px]">Reja</div>
              </div>
              <input
                className="!m-[0] w-[15px] h-[15px] absolute top-[15px] left-[118px] overflow-hidden shrink-0 z-[1]"
                type="checkbox"
              />
            </div>
            <div className="flex-1 bg-darkslategray-300 box-border overflow-hidden flex flex-col items-start justify-start relative min-w-[140px] border-[1px] border-solid border-white">
              <div className="self-stretch bg-darkorange overflow-hidden flex flex-row items-start justify-start border-[1px] border-solid border-white">
                <div className="relative [text-decoration:underline] inline-block min-w-[32px]">
                  Menu
                </div>
              </div>
              <div className="rounded-mini bg-darkslategray-300 overflow-hidden flex flex-row items-start justify-start py-0 pr-[3px] pl-0 whitespace-nowrap">
                <div className="relative">Sate ayam + lontong 50</div>
              </div>
              <div className="self-stretch bg-darkslategray-300 overflow-hidden flex flex-row items-start justify-start border-[1px] border-solid border-white">
                <div className="w-[143px] rounded-mini bg-darkslategray-300 overflow-hidden shrink-0 flex flex-row items-start justify-start whitespace-nowrap">
                  <div className="w-[173px] relative inline-block shrink-0">
                    Sate kambing tanpa bulu
                  </div>
                </div>
              </div>
              <img
                className="w-2 h-2 absolute !m-[0] top-[49px] left-[130px]"
                loading="lazy"
                alt=""
                src="/vector-11.svg"
              />
            </div>
            <div className="w-[70px] bg-darkslategray-300 overflow-hidden shrink-0 flex flex-col items-start justify-start">
              <div className="self-stretch bg-darkorange overflow-hidden flex flex-row items-start justify-start py-0 pr-5 pl-0 border-[1px] border-solid border-white">
                <div className="relative [text-decoration:underline] inline-block min-w-[48px]">
                  Subtotal
                </div>
              </div>
              <div className="self-stretch bg-darkslategray-300 overflow-hidden flex flex-row items-start justify-start py-0 pr-3 pl-0 border-[1px] border-solid border-white">
                <div className="relative inline-block min-w-[56px] whitespace-nowrap">
                  Rp34,345
                </div>
              </div>
              <div className="self-stretch bg-darkslategray-300 overflow-hidden flex flex-row items-start justify-start py-0 pr-3 pl-0 border-[1px] border-solid border-white">
                <div className="relative inline-block min-w-[56px] whitespace-nowrap">
                  Rp38,280
                </div>
              </div>
            </div>
            <div className="w-[70px] bg-darkslategray-300 overflow-hidden shrink-0 flex flex-col items-start justify-start">
              <div className="self-stretch bg-darkorange overflow-hidden flex flex-row items-start justify-start py-0 pr-[18px] pl-0 border-[1px] border-solid border-white">
                <div className="relative [text-decoration:underline] inline-block min-w-[50px]">
                  Discount
                </div>
              </div>
              <div className="self-stretch bg-darkslategray-300 overflow-hidden flex flex-row items-start justify-start py-0 pr-3 pl-0 border-[1px] border-solid border-white">
                <div className="relative inline-block min-w-[56px] whitespace-nowrap">
                  Rp34,345
                </div>
              </div>
              <div className="self-stretch bg-darkslategray-300 overflow-hidden flex flex-row items-start justify-start py-0 pr-3 pl-0 border-[1px] border-solid border-white">
                <div className="relative inline-block min-w-[56px] whitespace-nowrap">
                  Rp38,280
                </div>
              </div>
              <div className="self-stretch bg-darkorange overflow-hidden flex flex-row items-start justify-start py-0 pr-0.5 pl-0 whitespace-nowrap border-[1px] border-solid border-white">
                <div className="relative inline-block min-w-[66px]">
                  Grand Total
                </div>
              </div>
            </div>
            <div className="w-[70px] bg-darkslategray-300 overflow-hidden shrink-0 flex flex-col items-start justify-start">
              <div className="self-stretch bg-darkorange overflow-hidden flex flex-row items-start justify-start py-0 pr-[13px] pl-0 whitespace-nowrap border-[1px] border-solid border-white">
                <div className="relative [text-decoration:underline] inline-block min-w-[55px]">
                  Line Total
                </div>
              </div>
              <div className="self-stretch bg-darkslategray-300 overflow-hidden flex flex-row items-start justify-start py-0 pr-3 pl-0 border-[1px] border-solid border-white">
                <div className="relative inline-block min-w-[56px] whitespace-nowrap">
                  Rp34,345
                </div>
              </div>
              <div className="self-stretch bg-darkslategray-300 overflow-hidden flex flex-row items-start justify-start py-0 pr-3 pl-0 border-[1px] border-solid border-white">
                <div className="relative inline-block min-w-[56px] whitespace-nowrap">
                  Rp38,280
                </div>
              </div>
              <div className="self-stretch bg-darkorange overflow-hidden flex flex-row items-start justify-start py-0 pr-[17px] pl-0 border-[1px] border-solid border-white">
                <div className="relative inline-block min-w-[51px] whitespace-nowrap">
                  Rp46,151
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[19px]">
        <div className="w-[269px] flex flex-col items-start justify-start gap-[35px] mq450:gap-[17px]">
          <div className="self-stretch flex flex-row items-start justify-start py-0 pr-7 pl-[28.5px]">
            <div className="h-12 flex-1 relative tracking-[-0.04em] font-black flex items-end justify-center">
              Payment Method
            </div>
          </div>
          <div className="self-stretch bg-darkslategray-300 overflow-hidden flex flex-row items-start justify-start [row-gap:20px] text-left text-xs mq450:flex-wrap mq450:justify-center">
            <div className="w-[137px] bg-black overflow-hidden shrink-0 flex flex-col items-start justify-start [debug_commit:1de1738]">
              <div className="w-[235px] bg-darkslategray-300 overflow-hidden flex flex-row items-start justify-start text-darkslategray-300">
                <img
                  className="h-[15px] w-[15px] relative object-cover min-h-[15px]"
                  loading="lazy"
                  alt=""
                  src="/15@2x.png"
                />
                <div className="relative inline-block min-w-[41px]">
                  <span>{` `}</span>
                  <span className="text-white">Gopay</span>
                </div>
              </div>
              <div className="w-[235px] bg-darkslategray-300 overflow-hidden flex flex-row items-start justify-start">
                <img
                  className="h-[15px] w-[15px] relative object-cover min-h-[15px]"
                  loading="lazy"
                  alt=""
                  src="/16@2x.png"
                />
                <div className="relative inline-block min-w-[30px]">Dana</div>
                <div className="h-[15px] w-[15px] relative overflow-hidden shrink-0 hidden" />
              </div>
              <div className="w-[235px] bg-darkslategray-300 overflow-hidden flex flex-row items-start justify-start">
                <img
                  className="h-[15px] w-[15px] relative object-cover min-h-[15px]"
                  loading="lazy"
                  alt=""
                  src="/17@2x.png"
                />
                <div className="relative inline-block min-w-[25px]">BCA</div>
              </div>
            </div>
            <div className="w-[147px] flex flex-col items-start justify-start shrink-0 [debug_commit:1de1738]">
              <div className="self-stretch flex flex-row items-start justify-start">
                <div className="flex-1 relative">085974991595</div>
                <img
                  className="h-[15px] w-[15px] relative object-cover min-h-[15px]"
                  loading="lazy"
                  alt=""
                  src="/15@2x.png"
                />
              </div>
              <div className="relative inline-block min-w-[86px]">
                085974991595
              </div>
              <div className="relative inline-block min-w-[71px]">
                7750710497
              </div>
            </div>
            <div className="h-[15px] w-[41px] relative text-black inline-block shrink-0 [debug_commit:1de1738]">
              {" "}
              Gopay
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-start justify-center py-0 px-5">
        <button
          className="cursor-pointer [border:none] p-6 bg-darkorange w-80 rounded-3xs overflow-hidden shrink-0 flex flex-row items-start justify-start box-border max-w-[320px] whitespace-nowrap hover:bg-chocolate"
          onClick={onCtaButtonClick}
        >
          <b className="flex-1 relative text-5xl tracking-[-0.04em] font-inter text-white text-center">
            I Have Pay My Bill
          </b>
        </button>
      </div>
    </div>
  );
};

export default TextWrapper;
