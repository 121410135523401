import { FunctionComponent } from "react";
import LoginContainer from "../components/LoginContainer";

const Login: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-silver overflow-hidden flex flex-row items-start justify-start tracking-[normal] [row-gap:20px] text-left text-29xl text-white font-inter mq1500:flex-wrap">
      <div className="flex-1 bg-gray-100 overflow-hidden flex flex-col items-end justify-center py-[178px] px-[162px] box-border min-w-[706px] max-w-full mq850:py-[116px] mq850:px-10 mq850:box-border mq850:min-w-full mq450:pt-[75px] mq450:pb-[75px] mq450:box-border mq1225:pl-[81px] mq1225:pr-[81px] mq1225:box-border">
        <div className="w-[746px] [backdrop-filter:blur(48px)] bg-gray-300 flex flex-col items-start justify-start pt-[138px] px-[88px] pb-[149px] box-border relative gap-[40px] min-h-[613px] max-w-full mq850:gap-[20px] mq850:pl-11 mq850:pr-11 mq850:box-border mq450:pt-[90px] mq450:px-5 mq450:pb-[97px] mq450:box-border">
          <div className="w-[497px] flex flex-col items-start justify-start pt-0 pb-2 px-0 box-border max-w-full">
            <h1 className="m-0 w-[510px] relative text-inherit leading-[100%] font-semibold font-inherit inline-block max-w-[103%] shrink-0 mq850:text-19xl mq850:leading-[38px] mq450:text-10xl mq450:leading-[29px]">
              Welcome to the Splitbill Revolution!
            </h1>
          </div>
          <div className="w-[465px] h-52 absolute !m-[0] top-[256px] left-[88px] text-lg leading-[150%] inline-block">
            <p className="m-0">
              We bring you unparalleled convenience in splitting bills. With our
              motto, we invite you to enjoy a smart, swift, and enjoyable
              experience in dividing expenses. Make bill splitting more than
              just a task – turn it into joyful moments with us.
            </p>
            <p className="m-0">&nbsp;</p>
            <p className="m-0">-Splitter</p>
          </div>
        </div>
      </div>
      <LoginContainer />
    </div>
  );
};

export default Login;
