import { FunctionComponent, useState } from "react";

const LoginContainer: FunctionComponent = () => {
  const [checkBoxDefaultChecked, setCheckBoxDefaultChecked] = useState(true);
  return (
    <div className="w-[794px] bg-white flex flex-col items-start justify-start pt-[100px] px-[154px] pb-[110px] box-border gap-[48px] min-w-[794px] max-w-full text-left text-13xl text-darkslategray-100 font-inter mq850:pt-[65px] mq850:px-[77px] mq850:pb-[71px] mq850:box-border mq850:min-w-full mq450:gap-[24px] mq450:pt-[42px] mq450:px-5 mq450:pb-[46px] mq450:box-border mq1500:flex-1">
      <img
        className="w-[342px] h-[78px] relative object-cover max-w-full"
        loading="lazy"
        alt=""
        src="/ench-opt-1-1@2x.png"
      />
      <div className="w-[413px] flex flex-row items-start justify-start py-0 px-[33px] box-border max-w-full">
        <div className="flex-1 flex flex-col items-start justify-start gap-[8px] max-w-full">
          <h1 className="m-0 self-stretch relative text-inherit leading-[150%] font-bold font-inherit mq850:text-7xl mq850:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
            Hello
          </h1>
          <div className="self-stretch relative text-lg leading-[150%]">
            Enter your email and password to login.
          </div>
        </div>
      </div>
      <form className="m-0 self-stretch rounded-2xl flex flex-col items-start justify-start pt-8 px-8 pb-12 box-border gap-[48px] max-w-full mq850:gap-[24px] mq850:pt-[21px] mq850:pb-[31px] mq850:box-border">
        <div className="self-stretch rounded-2xl flex flex-col items-start justify-start gap-[24px] max-w-full">
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px] max-w-full">
            <div className="self-stretch relative text-base leading-[100%] font-semibold font-inter text-darkslategray-100 text-left">
              Email
            </div>
            <div className="self-stretch rounded-lg bg-gray-800 box-border flex flex-row items-start justify-start py-4 px-[15px] max-w-full border-[1px] border-solid border-thistle">
              <input
                className="w-full [border:none] [outline:none] font-inter text-base bg-[transparent] h-6 flex-1 relative leading-[24px] text-darkslategray-100 text-left inline-block min-w-[230px] whitespace-nowrap max-w-full p-0"
                placeholder="johndoe@test.com"
                type="text"
              />
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px] max-w-full">
            <div className="self-stretch relative text-base leading-[100%] font-semibold font-inter text-darkslategray-100 text-left">
              Password
            </div>
            <div className="self-stretch rounded-lg bg-gray-800 box-border flex flex-row items-start justify-start py-4 px-[15px] gap-[8px] max-w-full border-[1px] border-solid border-thistle mq850:overflow-x-auto mq450:flex-wrap">
              <div className="flex-1 flex flex-col items-start justify-start pt-1.5 px-0 pb-0 box-border min-w-[345px] max-w-full">
                <div className="self-stretch flex flex-row items-start justify-start py-0.5 px-0 gap-[4px] mq450:flex-wrap">
                  <div className="h-2 w-2 relative rounded-[50%] bg-darkslateblue mq450:w-full" />
                  <div className="h-2 w-2 relative rounded-[50%] bg-darkslateblue mq450:w-full" />
                  <div className="h-2 w-2 relative rounded-[50%] bg-darkslateblue mq450:w-full" />
                  <div className="h-2 w-2 relative rounded-[50%] bg-darkslateblue mq450:w-full" />
                  <div className="h-2 w-2 relative rounded-[50%] bg-darkslateblue mq450:w-full" />
                  <div className="h-2 w-2 relative rounded-[50%] bg-darkslateblue mq450:w-full" />
                  <div className="h-2 w-2 relative rounded-[50%] bg-darkslateblue mq450:w-full" />
                  <div className="h-2 w-2 relative rounded-[50%] bg-darkslateblue mq450:w-full" />
                </div>
              </div>
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0"
                alt=""
                src="/eye--off2.svg"
              />
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
            <div className="flex flex-row items-start justify-start gap-[8px]">
              <input
                className="accent-darkorange m-0 h-4 w-4 relative overflow-hidden shrink-0 min-h-[16px]"
                checked={checkBoxDefaultChecked}
                type="checkbox"
                onChange={(event) =>
                  setCheckBoxDefaultChecked(event.target.checked)
                }
              />
              <div className="relative text-base leading-[100%] font-inter text-darkslategray-100 text-left inline-block min-w-[110px]">
                Remember me
              </div>
            </div>
            <div className="w-[136px] relative text-base [text-decoration:underline] leading-[100%] font-inter text-darkslategray-100 text-right inline-block">
              Forgot password?
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start gap-[24px]">
            <button className="cursor-pointer py-4 px-[15px] bg-darkorange flex-1 rounded-lg flex flex-row items-start justify-start border-[1px] border-solid border-black hover:bg-chocolate hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-200">
              <div className="flex-1 relative text-base leading-[100%] font-semibold font-inter text-white text-center">
                Login
              </div>
            </button>
            <button className="cursor-pointer py-4 px-[15px] bg-[transparent] flex-1 rounded-lg flex flex-row items-start justify-start whitespace-nowrap border-[1px] border-solid border-darkslategray-100 hover:bg-dimgray-200 hover:box-border hover:border-[1px] hover:border-solid hover:border-dimgray-100">
              <div className="flex-1 relative text-base leading-[100%] font-semibold font-inter text-darkslategray-100 text-center">
                Sign Up
              </div>
            </button>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-center justify-start gap-[16px]">
          <div className="self-stretch relative text-sm leading-[100%] font-inter text-darkslategray-100 text-center">
            Or, login with
          </div>
          <div className="flex flex-row items-start justify-center py-0 px-[12.5px]">
            <button className="cursor-pointer py-3 px-[59px] bg-[transparent] h-[] rounded box-border flex flex-row items-start justify-center gap-[8px] whitespace-nowrap border-[1px] border-solid border-thistle hover:bg-slategray-200 hover:box-border hover:border-[1px] hover:border-solid hover:border-slategray-100">
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
                alt=""
                src="/flatcoloriconsgoogle.svg"
              />
              <div className="h-6 w-12 relative text-sm leading-[100%] font-medium font-inter text-darkslategray-100 text-center flex items-center justify-center shrink-0 min-w-[48px]">{`Google `}</div>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginContainer;
