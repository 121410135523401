import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaChevronDown, FaChevronUp, FaShareAlt, FaWhatsapp, FaTelegramPlane, FaLine } from 'react-icons/fa';
import { formatCurrency } from '../utils/utils';

const API_URL = 'https://api.splitbill.io';

interface ContactOrderDetails {
  name: string;
  deliveryFee: number;
  otherFees: number;
  tax: number;
  discount: number;
  grandTotal: number;
  items: Item[];
}

interface Item {
  name: string;
  qty: number;
  subtotal: number;
  buyers: Buyer[];
  discount?: number;
  discountedPrice?: number;
  total?: number;
}

interface Buyer {
  name: string;
  qty: number;
}

interface PaymentMethod {
  name: string;
  account_number: string;
}

const ResultPage: React.FC = () => {
  const { requestId } = useParams<{ requestId: string }>();
  const [data, setData] = useState<any>(null);
  const [showDonatePopup, setShowDonatePopup] = useState(false);
  const [collapsedSections, setCollapsedSections] = useState<{ [key: number]: boolean }>({});
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const sectionRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/bill/${requestId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const result = await response.json();
        setData(result.data);
        console.log("Fetched data:", result.data);

        const initialCollapsedState = result.data.contactOrderDetails.reduce((acc: any, _: any, index: number) => {
          acc[index] = true;
          return acc;
        }, {});
        setCollapsedSections(initialCollapsedState);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [requestId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowDonatePopup(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const handleShareClick = () => {
    setShowSharePopup(true);
  };

  const handleClosePopup = () => {
    setShowDonatePopup(false);
  };

  const toggleCollapse = (index: number) => {
    setCollapsedSections((prev) => {
      const newState = {
        ...prev,
        [index]: !prev[index]
      };

      if (!newState[index] && sectionRefs.current[index]) {
        sectionRefs.current[index]!.scrollIntoView({
          behavior: 'smooth',
          block: 'center', // Scroll element to center of the viewport
          inline: 'nearest'
        });
      }

      return newState;
    });
  };

  const closeSharePopup = () => {
    setShowSharePopup(false);
  };

  const shareLink = window.location.href;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareLink);
    toast.success('Link copied to clipboard!');
  };

  const openFeedbackModal = () => {
    setShowFeedbackModal(true);
  };

  const closeFeedbackModal = () => {
    setShowFeedbackModal(false);
    setFeedbackSubmitted(false);
  };

  const handleFeedbackChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedbackText(e.target.value);
  };

  const submitFeedback = async () => {
    const feedbackData = {
      userId: Math.floor(Math.random() * 1000000).toString(),
      text: feedbackText
    };

    try {
      const response = await fetch(`${API_URL}/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(feedbackData),
      });

      if (!response.ok) {
        throw new Error('Failed to submit feedback');
      }

      setFeedbackSubmitted(true);
    } catch (error) {
      console.error('Error submitting feedback:', error);
      toast.error('Failed to submit feedback');
    }
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  if (!data.contactOrderDetails) {
    return <div>Error: No order details found.</div>;
  }

  return (
    <div className="min-h-screen bg-zinc-900 flex justify-center items-center font-inter">
      <div className="relative max-w-4xl mx-auto p-6">
        <div className="absolute inset-0 rounded-lg blur-lg running-color-bg"></div>
        <div className="relative bg-zinc-800 text-white rounded-lg shadow-lg p-6 border-2 border-transparent z-10">
          <div className="flex justify-center mb-6">
            <img
              className="w-[454px] h-[104px] relative object-contain max-w-full"
              loading="lazy"
              alt="Logo Splitbill.io"
              src="/logo-text--white-bg-transparan-1@2x1.png"
            />
          </div>
          {data.paymentMethods && data.paymentMethods.length > 0 &&
            <div>
              <h1 className="text-3xl font-bold mb-4">Payment Methods</h1>
              <div className="flex justify-center bg-zinc-700 rounded-md">
                <table className="w-full text-left table-auto ">
                  <tbody>
                    {data.paymentMethods.map((paymentMethod: PaymentMethod, idx: number) => (
                      <tr key={idx}>
                        <td className="border-b border-gray-600 py-2 px-4">{paymentMethod.name}</td>
                        <td
                          className="border-b border-gray-600 py-2 px-4 text-right cursor-pointer"
                          onClick={() => {
                            navigator.clipboard.writeText(paymentMethod.account_number);
                            toast.success('Copied to clipboard!');
                          }}
                          title="Click to copy"
                        >
                          {paymentMethod.account_number}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <br />
            </div>
          }
          <h1 className="text-3xl font-bold mb-4">Order Summary</h1>
          {data.contactOrderDetails.map((contact: ContactOrderDetails, index: number) => (
            <div key={index} ref={(el) => (sectionRefs.current[index] = el)} className="mb-6">
              <div className="p-4 border border-gray-700 rounded-md bg-zinc-700 mb-4">
                <div className="flex justify-between items-center cursor-pointer bg-darkorange rounded-lg px-4 py-2" onClick={() => toggleCollapse(index)}>
                  <span className="text-xl font-semibold">{contact.name}'s Total</span>
                  <span className="text-xl font-semibold ml-auto mr-4">{formatCurrency(contact.grandTotal)}</span>
                  {collapsedSections[index] ? <FaChevronDown className="text-xl transition-transform" /> : <FaChevronUp className="text-xl transition-transform" />}
                </div>
                <div className={`collapsible-content ${!collapsedSections[index] ? 'collapsible-content-expanded' : ''}`}>
                  <h3 className="text-lg font-semibold mb-2">Items</h3>
                  <table className="w-full text-left table-auto mb-4">
                    <tbody>
                      {contact.items.map((item: Item, idx: number) => (
                        <tr key={idx}>
                          <td className="w-full border-b border-gray-600 py-2 px-4">{item.name}</td>
                          <td className="border-b border-gray-600 py-2 px-4 text-right">x{item.qty}</td>
                          <td className="border-b border-gray-600 py-2 px-4 text-right">{formatCurrency(item.subtotal)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <h3 className="text-lg font-semibold mb-2">Bill Details</h3>
                  <table className="w-full text-left table-auto">
                    <tbody>
                      <tr>
                        <td className="border-b border-gray-600 py-2 px-4">+ Delivery Fee</td>
                        <td className="border-b border-gray-600 py-2 px-4 text-right">{formatCurrency(contact.deliveryFee)}</td>
                      </tr>
                      <tr>
                        <td className="border-b border-gray-600 py-2 px-4">+ Other Fees</td>
                        <td className="border-b border-gray-600 py-2 px-4 text-right">{formatCurrency(contact.otherFees)}</td>
                      </tr>
                      <tr>
                        <td className="border-b border-gray-600 py-2 px-4">+ Tax</td>
                        <td className="border-b border-gray-600 py-2 px-4 text-right">{formatCurrency(contact.tax)}</td>
                      </tr>
                      <tr>
                        <td className="border-b border-gray-600 py-2 px-4">- Discount</td>
                        <td className="border-b border-gray-600 py-2 px-4 text-right">{formatCurrency(contact.discount)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
          <div className="flex justify-center">
            <button onClick={handleShareClick} className="w-full flex items-center justify-center text-base cursor-pointer px-4 py-2 bg-darkorange text-white font-inter rounded-md hover:bg-chocolate" style={{ height: '40px' }}>
              <FaShareAlt className="mr-2" />
              Share
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />

      {showSharePopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-zinc-700 p-6 rounded-lg shadow-lg text-center relative">
            <button onClick={closeSharePopup} className="p-0 font-bold text-4xl bg-zinc-700 text-red-500 hover:text-red-500 cursor-pointer absolute" style={{ right: '1rem', top: '1rem' }}>
              X
            </button>
            <h2 className="text-xl font-semibold mb-4 text-white">Share this link</h2>
            <div className="flex justify-center mb-4">
              <a href={`https://wa.me/?text=${encodeURIComponent(shareLink)}`} target="_blank" rel="noopener noreferrer" className="text-green-500 mx-2">
                <FaWhatsapp size={40} />
              </a>
              <a href={`https://t.me/share/url?url=${encodeURIComponent(shareLink)}`} target="_blank" rel="noopener noreferrer" className="text-blue-500 mx-2">
                <FaTelegramPlane size={40} />
              </a>
              <a href={`https://line.me/R/msg/text/?${encodeURIComponent(shareLink)}`} target="_blank" rel="noopener noreferrer" className="text-green-600 mx-2">
                <FaLine size={40} />
              </a>
            </div>
            <button onClick={copyToClipboard} className="bg-darkorange text-white px-4 py-2 rounded-md hover:bg-blue-600 mb-2 cursor-pointer">
              Copy Link
            </button>
          </div>
        </div>
      )}

      {showDonatePopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-zinc-700 p-6 rounded-lg shadow-lg text-center relative">
            <button onClick={handleClosePopup} className="p-0 font-bold text-4xl bg-zinc-700 text-red-500 hover:text-red-500 cursor-pointer absolute" style={{ right: '1rem', top: '1rem' }}>
              X
            </button>
            <h2 className="text-xl font-semibold mb-4 text-white">Support Us</h2>
            <p className="mb-6 text-white">If you find this app helpful, please consider donating!</p>
            <button onClick={() => window.open('https://teer.id/splitbill.io', '_blank')} className="bg-darkorange text-white px-4 py-2 rounded-md hover:bg-chocolate mb-2 cursor-pointer mr-2">
              Donate Now
            </button>

            <button onClick={openFeedbackModal} className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 cursor-pointer mt-4 ml-2">
              Feedback
            </button>
          </div>
        </div>
      )}

      {showFeedbackModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-zinc-700 p-6 rounded-lg shadow-lg text-center relative">
            <button onClick={closeFeedbackModal} className="p-0 font-bold text-4xl bg-zinc-700 text-red-500 hover:text-red-500 cursor-pointer absolute" style={{ right: '1rem', top: '1rem' }}>
              X
            </button>
            {!feedbackSubmitted ? (
              <>
                <h2 className="text-xl font-semibold mb-4 text-white">Feedback</h2>
                <textarea
                  value={feedbackText}
                  onChange={handleFeedbackChange}
                  className="h-40 p-2 mb-4 rounded-md bg-zinc-800 text-white"
                  placeholder="Your feedback..."
                /> <br />
                <button onClick={submitFeedback} className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 cursor-pointer">
                  Submit
                </button>
              </>
            ) : (
              <h2 className="text-xl font-semibold mb-4 text-white">Thank you for your feedback!</h2>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ResultPage;
