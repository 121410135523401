import { FunctionComponent, useState } from "react";
import Frame from "../components/Frame";

const BillInput: FunctionComponent = () => {
  const [phcheckFillIconChecked, setPhcheckFillIconChecked] = useState(true);
  return (
    <div className="w-full relative bg-silver overflow-hidden flex flex-row items-start justify-start leading-[normal] tracking-[normal]">
      <footer className="flex-1 bg-white flex flex-row items-start justify-start py-0 pr-[34px] pl-0 box-border gap-[32px] max-w-full mq750:gap-[16px] mq750:pl-5 mq750:box-border">
        <Frame />
        <section className="flex-1 flex flex-col items-start justify-start pt-[26px] px-0 pb-0 box-border max-w-[calc(100%_-_152px)] mq750:max-w-full">
          <div className="self-stretch flex flex-col items-start justify-start gap-[165px] max-w-full mq750:gap-[41px] mq450:gap-[21px] mq1125:gap-[82px]">
            <header className="self-stretch flex flex-col items-start justify-start gap-[51px] max-w-full text-left text-base text-darkslategray-100 font-inter mq750:gap-[25px]">
              <div className="w-[392px] flex flex-row items-start justify-start py-0 px-1.5 box-border max-w-full text-13xl">
                <div className="flex-1 overflow-hidden flex flex-col items-end justify-center max-w-full">
                  <div className="self-stretch flex flex-col items-start justify-start relative gap-[8px] max-w-full">
                    <h2 className="m-0 w-[347px] relative text-inherit leading-[150%] font-bold font-inherit inline-block max-w-full">
                      Hello
                    </h2>
                    <h3 className="m-0 self-stretch relative text-lg leading-[150%] font-normal font-inherit whitespace-nowrap">{`This is Group “Office Gang” `}</h3>
                    <button className="cursor-pointer py-px px-1.5 bg-darkorange w-[140px] !m-[0] absolute top-[60px] left-[240px] rounded-lg box-border flex flex-row items-center justify-center whitespace-nowrap border-[1px] border-solid border-black hover:bg-chocolate hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-200">
                      <img
                        className="h-4 w-4 relative overflow-hidden shrink-0"
                        alt=""
                        src="/bxedit.svg"
                      />
                      <div className="flex-1 relative text-xs leading-[100%] font-semibold font-inter text-white text-center">
                        Edit Group Name
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[17px] box-border max-w-full">
                <div className="flex-1 flex flex-col items-start justify-start gap-[22px] max-w-full">
                  <div className="w-[416px] relative leading-[100%] font-semibold inline-block max-w-full">
                    Expense
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[31px] max-w-full text-xs text-white mq750:gap-[15px]">
                    <div className="self-stretch flex flex-row items-end justify-start max-w-full">
                      <div className="flex-1 flex flex-col items-start justify-end pt-0 px-0 pb-1 box-border max-w-full">
                        <div className="self-stretch bg-white overflow-hidden flex flex-row items-start justify-start">
                          <div className="w-[217px] bg-white overflow-hidden shrink-0 flex flex-col items-start justify-start">
                            <div className="self-stretch bg-darkorange overflow-hidden flex flex-row items-start justify-start">
                              <div className="relative inline-block min-w-[67px] whitespace-nowrap">
                                Store Name
                              </div>
                            </div>
                            <input
                              className="w-full [border:none] [outline:none] bg-white self-stretch h-[15px] overflow-hidden shrink-0 flex flex-row items-start justify-start font-inter text-xs text-black min-w-[130px]"
                              placeholder="Sate Partai Perindo"
                              type="text"
                            />
                          </div>
                          <div className="w-[196px] bg-white overflow-hidden shrink-0 flex flex-col items-start justify-start">
                            <div className="self-stretch bg-darkorange overflow-hidden flex flex-row items-start justify-start">
                              <div className="relative inline-block min-w-[27px]">
                                Date
                              </div>
                            </div>
                            <input
                              className="w-full [border:none] [outline:none] bg-white self-stretch h-[15px] overflow-hidden shrink-0 flex flex-row items-start justify-start font-inter text-xs text-black min-w-[118px]"
                              placeholder="16 April 2024"
                              type="text"
                            />
                          </div>
                          <div className="w-[172px] bg-white overflow-hidden shrink-0 flex flex-col items-start justify-start">
                            <div className="self-stretch bg-darkorange overflow-hidden flex flex-row items-start justify-start">
                              <div className="relative inline-block min-w-[48px]">
                                Subtotal
                              </div>
                            </div>
                            <input
                              className="w-full [border:none] [outline:none] bg-white self-stretch h-[15px] overflow-hidden shrink-0 flex flex-row items-start justify-start font-inter text-xs text-black min-w-[103px]"
                              placeholder="Rp232,000"
                              type="text"
                            />
                          </div>
                          <div className="w-[150px] bg-white overflow-hidden shrink-0 flex flex-col items-start justify-start">
                            <div className="self-stretch bg-darkorange overflow-hidden flex flex-row items-start justify-start">
                              <div className="relative inline-block min-w-[47px]">
                                Delivery
                              </div>
                            </div>
                            <div className="self-stretch bg-white overflow-hidden flex flex-row items-start justify-start text-black">
                              <div className="relative inline-block min-w-[55px] whitespace-nowrap">
                                Rp28,000
                              </div>
                            </div>
                          </div>
                          <div className="w-[106px] bg-white overflow-hidden shrink-0 flex flex-col items-start justify-start">
                            <div className="self-stretch bg-darkorange overflow-hidden flex flex-row items-start justify-start">
                              <div className="relative inline-block min-w-[39px]">
                                Others
                              </div>
                            </div>
                            <div className="self-stretch bg-white overflow-hidden flex flex-row items-start justify-start text-black">
                              <div className="relative inline-block min-w-[23px] whitespace-nowrap">
                                Rp0
                              </div>
                            </div>
                          </div>
                          <div className="w-[152px] bg-white overflow-hidden shrink-0 flex flex-col items-start justify-start">
                            <div className="self-stretch bg-darkorange overflow-hidden flex flex-row items-start justify-start">
                              <div className="relative inline-block min-w-[50px]">
                                Discount
                              </div>
                            </div>
                            <div className="self-stretch bg-white overflow-hidden flex flex-row items-start justify-start text-black">
                              <div className="relative inline-block min-w-[56px] whitespace-nowrap">
                                Rp66,000
                              </div>
                            </div>
                          </div>
                          <div className="w-[166px] bg-white overflow-hidden shrink-0 flex flex-col items-start justify-start">
                            <div className="self-stretch bg-darkorange overflow-hidden flex flex-row items-start justify-start">
                              <div className="relative inline-block min-w-[28px]">
                                Total
                              </div>
                            </div>
                            <div className="self-stretch bg-white overflow-hidden flex flex-row items-start justify-start text-black">
                              <div className="relative inline-block min-w-[61px] whitespace-nowrap">
                                Rp194,000
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="h-6 w-6 relative">
                        <img
                          className="absolute top-[0px] left-[0px] w-full h-full overflow-hidden z-[1]"
                          loading="lazy"
                          alt=""
                          src="/icbaselineplus.svg"
                        />
                        <div className="absolute top-[0px] left-[0px] w-5 h-5 overflow-hidden hidden z-[2]" />
                      </div>
                    </div>
                    <div className="flex flex-row items-end justify-start gap-[6px] max-w-full text-black">
                      <div className="w-[841px] bg-white overflow-hidden shrink-0 flex flex-col items-start justify-start max-w-full">
                        <div className="self-stretch bg-white overflow-hidden flex flex-row items-start justify-start py-0 pr-1.5 pl-0 text-white">
                          <div className="flex-1 bg-darkorange overflow-hidden flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[62px] whitespace-nowrap">
                              Item Name
                            </div>
                          </div>
                          <div className="bg-darkorange overflow-hidden flex flex-row items-start justify-start py-0 pr-1 pl-0">
                            <div className="relative inline-block min-w-[21px]">
                              Qty
                            </div>
                          </div>
                          <div className="w-[147px] bg-darkorange overflow-hidden shrink-0 flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[29px]">
                              Price
                            </div>
                          </div>
                          <div className="w-[146px] bg-darkorange overflow-hidden shrink-0 flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[50px]">
                              Discount
                            </div>
                          </div>
                          <div className="w-[145px] bg-darkorange overflow-hidden shrink-0 flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[65px]">
                              Discounted
                            </div>
                          </div>
                          <div className="w-[159px] bg-darkorange overflow-hidden shrink-0 flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[48px]">
                              Subtotal
                            </div>
                          </div>
                        </div>
                        <div className="w-[998px] bg-gainsboro overflow-hidden flex flex-row items-start justify-start py-0 pr-[170px] pl-0 box-border max-w-[119%] shrink-0 mq750:pr-[42px] mq750:box-border mq1050:pr-[85px] mq1050:box-border">
                          <div className="flex-1 bg-white overflow-hidden flex flex-row items-start justify-start">
                            <div className="relative whitespace-nowrap">
                              Sate ayam + lontong 50
                            </div>
                          </div>
                          <div className="bg-white overflow-hidden flex flex-row items-start justify-start py-0 pr-[19px] pl-0">
                            <div className="relative inline-block min-w-[6px]">
                              1
                            </div>
                          </div>
                          <div className="w-[147px] bg-white overflow-hidden shrink-0 flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[56px] whitespace-nowrap">
                              Rp48,000
                            </div>
                          </div>
                          <div className="w-[146px] bg-gainsboro overflow-hidden shrink-0 flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[54px] whitespace-nowrap">
                              Rp13,655
                            </div>
                          </div>
                          <div className="w-[145px] bg-gainsboro overflow-hidden shrink-0 flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[56px] whitespace-nowrap">
                              Rp34,345
                            </div>
                          </div>
                          <div className="w-[146px] bg-gainsboro overflow-hidden shrink-0 flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[56px] whitespace-nowrap">
                              Rp34,345
                            </div>
                          </div>
                        </div>
                        <div className="w-[998px] bg-gainsboro overflow-hidden flex flex-row items-start justify-start py-0 pr-[170px] pl-0 box-border max-w-[119%] shrink-0 mq750:pr-[42px] mq750:box-border mq1050:pr-[85px] mq1050:box-border">
                          <div className="flex-1 bg-white overflow-hidden flex flex-row items-start justify-start">
                            <div className="relative whitespace-nowrap">
                              Sate kambing + lontong 10
                            </div>
                          </div>
                          <div className="bg-white overflow-hidden flex flex-row items-start justify-start py-0 pr-[19px] pl-0">
                            <div className="relative inline-block min-w-[6px]">
                              1
                            </div>
                          </div>
                          <div className="w-[147px] bg-white overflow-hidden shrink-0 flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[56px] whitespace-nowrap">
                              Rp53,500
                            </div>
                          </div>
                          <div className="w-[146px] bg-gainsboro overflow-hidden shrink-0 flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[53px] whitespace-nowrap">
                              Rp15,220
                            </div>
                          </div>
                          <div className="w-[145px] bg-gainsboro overflow-hidden shrink-0 flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[56px] whitespace-nowrap">
                              Rp38,280
                            </div>
                          </div>
                          <div className="w-[146px] bg-gainsboro overflow-hidden shrink-0 flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[56px] whitespace-nowrap">
                              Rp38,280
                            </div>
                          </div>
                        </div>
                        <div className="w-[998px] bg-gainsboro overflow-hidden flex flex-row items-start justify-start py-0 pr-[170px] pl-0 box-border max-w-[119%] shrink-0 mq750:pr-[42px] mq750:box-border mq1050:pr-[85px] mq1050:box-border">
                          <div className="flex-1 bg-white overflow-hidden flex flex-row items-start justify-start">
                            <div className="relative whitespace-nowrap">
                              Sate kambing tanpa bulu
                            </div>
                          </div>
                          <div className="bg-white overflow-hidden flex flex-row items-start justify-start py-0 pr-[19px] pl-0">
                            <div className="relative inline-block min-w-[6px]">
                              1
                            </div>
                          </div>
                          <div className="w-[147px] bg-white overflow-hidden shrink-0 flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[56px] whitespace-nowrap">
                              Rp64,500
                            </div>
                          </div>
                          <div className="w-[146px] bg-gainsboro overflow-hidden shrink-0 flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[54px] whitespace-nowrap">
                              Rp18,349
                            </div>
                          </div>
                          <div className="w-[145px] bg-gainsboro overflow-hidden shrink-0 flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[51px] whitespace-nowrap">
                              Rp46,151
                            </div>
                          </div>
                          <div className="w-[146px] bg-gainsboro overflow-hidden shrink-0 flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[51px] whitespace-nowrap">
                              Rp46,151
                            </div>
                          </div>
                        </div>
                        <div className="w-[998px] bg-gainsboro overflow-hidden flex flex-row items-start justify-start py-0 pr-[170px] pl-0 box-border max-w-[119%] shrink-0 mq750:pr-[42px] mq750:box-border mq1050:pr-[85px] mq1050:box-border">
                          <div className="flex-1 bg-white overflow-hidden flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[124px] whitespace-nowrap">
                              Sate ayam tanpa telur
                            </div>
                          </div>
                          <div className="bg-white overflow-hidden flex flex-row items-start justify-start py-0 pr-[19px] pl-0">
                            <div className="relative inline-block min-w-[6px]">
                              1
                            </div>
                          </div>
                          <div className="w-[147px] bg-white overflow-hidden shrink-0 flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[56px] whitespace-nowrap">
                              Rp66,000
                            </div>
                          </div>
                          <div className="w-[146px] bg-gainsboro overflow-hidden shrink-0 flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[53px] whitespace-nowrap">
                              Rp18,776
                            </div>
                          </div>
                          <div className="w-[145px] bg-gainsboro overflow-hidden shrink-0 flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[54px] whitespace-nowrap">
                              Rp47,224
                            </div>
                          </div>
                          <div className="w-[146px] bg-gainsboro overflow-hidden shrink-0 flex flex-row items-start justify-start">
                            <div className="relative inline-block min-w-[54px] whitespace-nowrap">
                              Rp47,224
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-end pt-0 px-0 pb-0.5">
                        <div className="w-3 h-[25px] relative">
                          <img
                            className="absolute top-[17px] left-[3px] w-2 h-2"
                            alt=""
                            src="/more-options-icon.svg"
                          />
                          <img
                            className="absolute top-[0px] left-[0px] w-3 h-3 overflow-hidden"
                            loading="lazy"
                            alt=""
                            src="/carbondelete.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[692px] flex flex-row items-start justify-start pt-0 px-0.5 pb-[22px] box-border max-w-full">
                <div className="flex-1 flex flex-col items-start justify-start gap-[2px] max-w-full">
                  <div className="w-[446px] flex flex-row items-start justify-start py-0 px-[15px] box-border max-w-full">
                    <div className="flex-1 relative leading-[100%] font-semibold inline-block max-w-full">
                      Contact
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[17px] max-w-full text-3xs">
                    <div className="w-[446px] flex flex-row items-start justify-start py-0 px-[15px] box-border max-w-full">
                      <div className="flex-1 flex flex-row items-start justify-start max-w-full">
                        <div className="w-[416px] flex flex-col items-start justify-start pt-0.5 px-0 pb-0 box-border max-w-full">
                          <div className="self-stretch relative leading-[100%] font-medium whitespace-pre-wrap shrink-0">
                            Click To Save Contact to Your Contact List
                          </div>
                        </div>
                        <img
                          className="h-3 w-3 relative overflow-hidden shrink-0 z-[1] ml-[-389px]"
                          loading="lazy"
                          alt=""
                          src="/materialsymbolssave.svg"
                        />
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-end justify-start gap-[3px] max-w-full text-xs text-black">
                      <div className="flex flex-col items-start justify-end pt-0 px-0 pb-px">
                        <div className="w-3 h-[43px] relative">
                          <img
                            className="absolute top-[16px] left-[0px] w-3 h-3 overflow-hidden"
                            alt=""
                            src="/materialsymbolssave.svg"
                          />
                          <img
                            className="absolute top-[31px] left-[0px] w-3 h-3 overflow-hidden"
                            alt=""
                            src="/materialsymbolssave.svg"
                          />
                          <input
                            className="accent-darkorange m-0 absolute top-[0px] left-[0px] w-3 h-3 overflow-hidden"
                            checked={phcheckFillIconChecked}
                            type="checkbox"
                            onChange={(event) =>
                              setPhcheckFillIconChecked(event.target.checked)
                            }
                          />
                        </div>
                      </div>
                      <div className="flex-1 flex flex-col items-start justify-start py-0 pr-[11px] pl-0 box-border max-w-full">
                        <div className="self-stretch bg-white overflow-hidden flex flex-row items-start justify-start">
                          <div className="w-[137px] bg-white overflow-hidden shrink-0 flex flex-col items-start justify-start relative">
                            <div className="w-[235px] bg-darkorange overflow-hidden flex flex-row items-start justify-start text-white">
                              <div className="relative inline-block min-w-[34px]">
                                Name
                              </div>
                            </div>
                            <div className="w-[235px] bg-white overflow-hidden flex flex-row items-start justify-start">
                              <div className="relative inline-block min-w-[28px]">
                                Reza
                              </div>
                            </div>
                            <div className="w-[235px] bg-white overflow-hidden flex flex-row items-start justify-start py-0 pr-[102px] pl-0 box-border relative">
                              <div className="relative inline-block min-w-[40px]">
                                Helena
                              </div>
                              <img
                                className="h-2 w-[15px] absolute !m-[0] top-[4px] left-[118px]"
                                alt=""
                                src="/vector-1.svg"
                              />
                            </div>
                            <div className="w-[235px] bg-white overflow-hidden flex flex-row items-start justify-start py-0 pr-[102px] pl-0 box-border relative">
                              <div className="relative inline-block min-w-[29px]">
                                Yoga
                              </div>
                              <img
                                className="h-2 w-[15px] absolute !m-[0] top-[4px] left-[118px]"
                                alt=""
                                src="/vector-1.svg"
                              />
                            </div>
                            <img
                              className="w-[15px] h-[15px] absolute !m-[0] top-[15px] left-[118px] overflow-hidden shrink-0 z-[1]"
                              loading="lazy"
                              alt=""
                              src="/teenyiconsdownsolid.svg"
                            />
                          </div>
                          <div className="w-[132px] bg-white overflow-hidden shrink-0 flex flex-col items-start justify-start">
                            <div className="self-stretch bg-darkorange overflow-hidden flex flex-row items-start justify-start text-white">
                              <div className="relative inline-block min-w-[31px]">
                                Email
                              </div>
                            </div>
                            <div className="self-stretch bg-white overflow-hidden flex flex-row items-start justify-start">
                              <div className="flex-1 relative whitespace-nowrap">
                                rfabriza@gmail.com
                              </div>
                            </div>
                            <div className="bg-white overflow-hidden flex flex-row items-start justify-start py-0 pr-[19px] pl-0">
                              <div className="relative inline-block min-w-[113px] whitespace-nowrap">
                                rfabriza@gmail.com
                              </div>
                            </div>
                            <div className="bg-white overflow-hidden flex flex-row items-start justify-start py-0 pr-[19px] pl-0">
                              <div className="relative inline-block min-w-[113px] whitespace-nowrap">
                                rfabriza@gmail.com
                              </div>
                            </div>
                          </div>
                          <div className="w-[93px] bg-white overflow-hidden shrink-0 flex flex-col items-start justify-start">
                            <div className="self-stretch bg-darkorange overflow-hidden flex flex-row items-start justify-start text-white">
                              <div className="relative inline-block min-w-[36px]">
                                Phone
                              </div>
                            </div>
                            <div className="bg-white overflow-hidden flex flex-row items-start justify-start">
                              <div className="w-[86px] relative inline-block">
                                085974991595
                              </div>
                            </div>
                            <div className="bg-white overflow-hidden flex flex-row items-start justify-start">
                              <div className="w-[86px] relative inline-block">
                                085974991595
                              </div>
                            </div>
                            <div className="bg-white overflow-hidden flex flex-row items-start justify-start">
                              <div className="w-[86px] relative inline-block">
                                085974991595
                              </div>
                            </div>
                          </div>
                          <div className="flex-1 bg-white overflow-hidden flex flex-col items-start justify-start relative">
                            <div className="self-stretch bg-darkorange overflow-hidden flex flex-row items-start justify-start text-white">
                              <div className="relative inline-block min-w-[58px] whitespace-nowrap">
                                Add menu
                              </div>
                            </div>
                            <div className="rounded-mini bg-gold overflow-hidden flex flex-row items-start justify-start py-0 pr-[3px] pl-0 whitespace-nowrap">
                              <div className="relative">
                                Sate ayam + lontong 50
                              </div>
                            </div>
                            <div className="self-stretch bg-white overflow-hidden flex flex-row items-start justify-start py-0 pr-[72px] pl-0 relative">
                              <div className="rounded-mini bg-dodgerblue overflow-hidden flex flex-row items-start justify-start py-0 pr-0.5 pl-0 whitespace-nowrap">
                                <div className="relative">
                                  Sate kambing tanpa bulu
                                </div>
                              </div>
                              <img
                                className="h-2 w-2 absolute !m-[0] top-[3px] left-[147px]"
                                alt=""
                                src="/more-options-icon.svg"
                              />
                            </div>
                            <img
                              className="w-2 h-2 absolute !m-[0] top-[49px] left-[130px]"
                              alt=""
                              src="/more-options-icon.svg"
                            />
                            <div className="w-[127px] rounded-mini bg-forestgreen overflow-hidden flex flex-row items-start justify-start whitespace-nowrap">
                              <div className="w-[204px] relative inline-block shrink-0">
                                Sate ayam tanpa telur
                              </div>
                            </div>
                            <img
                              className="w-2 h-2 absolute !m-[0] top-[18px] left-[142px]"
                              alt=""
                              src="/more-options-icon.svg"
                            />
                          </div>
                          <div className="bg-white overflow-hidden flex flex-col items-start justify-start">
                            <div className="bg-darkorange overflow-hidden flex flex-row items-start justify-start py-0 pr-[22px] pl-0 text-white">
                              <div className="relative inline-block min-w-[48px]">
                                Subtotal
                              </div>
                            </div>
                            <div className="bg-gainsboro overflow-hidden flex flex-row items-start justify-start py-0 pr-3.5 pl-0">
                              <div className="relative inline-block min-w-[56px] whitespace-nowrap">
                                Rp34,345
                              </div>
                            </div>
                            <div className="bg-gainsboro overflow-hidden flex flex-row items-start justify-start py-0 pr-3.5 pl-0">
                              <div className="relative inline-block min-w-[56px] whitespace-nowrap">
                                Rp38,280
                              </div>
                            </div>
                            <div className="bg-gainsboro overflow-hidden flex flex-row items-start justify-start py-0 pr-[19px] pl-0">
                              <div className="relative inline-block min-w-[51px] whitespace-nowrap">
                                Rp46,151
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-end pt-0 px-0 pb-[3px]">
                        <div className="w-3 h-[26px] relative">
                          <img
                            className="absolute top-[18px] left-[3px] w-2 h-2"
                            alt=""
                            src="/more-options-icon.svg"
                          />
                          <img
                            className="absolute top-[0px] left-[0px] w-3 h-3 overflow-hidden"
                            alt=""
                            src="/carbondelete.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[433px] flex flex-col items-start justify-start gap-[15px] max-w-full">
                <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[17px] box-border max-w-full">
                  <div className="flex-1 flex flex-col items-start justify-start gap-[4px] max-w-full">
                    <div className="self-stretch relative leading-[100%] font-semibold whitespace-nowrap">
                      Payment Method
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start max-w-full text-3xs">
                      <div className="w-[416px] relative leading-[100%] font-medium whitespace-pre-wrap inline-block shrink-0 max-w-full">
                        Click To Save Payment Method
                      </div>
                      <img
                        className="h-3 w-3 relative overflow-hidden shrink-0 z-[1] ml-[-388px]"
                        alt=""
                        src="/materialsymbolssave.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-[286px] flex flex-row items-end justify-start gap-[4px] text-xs text-black">
                  <div className="flex flex-col items-start justify-end pt-0 px-0 pb-px">
                    <div className="w-[13px] h-[43px] relative">
                      <img
                        className="absolute top-[16px] left-[0px] w-3 h-3 overflow-hidden"
                        alt=""
                        src="/materialsymbolssave.svg"
                      />
                      <input
                        className="m-0 absolute top-[0px] left-[1px] w-3 h-3 overflow-hidden"
                        type="checkbox"
                      />
                      <input
                        className="m-0 absolute top-[31px] left-[1px] w-3 h-3 overflow-hidden"
                        type="checkbox"
                      />
                    </div>
                  </div>
                  <div className="flex-1 bg-white overflow-hidden flex flex-row items-start justify-start">
                    <div className="w-[137px] bg-white overflow-hidden shrink-0 flex flex-col items-start justify-start relative">
                      <div className="w-[235px] bg-darkorange overflow-hidden flex flex-row items-start justify-start text-white">
                        <div className="relative inline-block min-w-[34px]">
                          Name
                        </div>
                      </div>
                      <div className="bg-white overflow-hidden flex flex-row items-start justify-start py-0 pr-[179px] pl-0">
                        <img
                          className="h-[15px] w-[15px] relative object-cover min-h-[15px]"
                          alt=""
                          src="/15@2x.png"
                        />
                        <div className="relative inline-block min-w-[41px] whitespace-nowrap">
                          {" "}
                          Gopay
                        </div>
                      </div>
                      <div className="w-[235px] bg-white overflow-hidden flex flex-row items-start justify-start py-0 pr-[102px] pl-0 box-border relative">
                        <img
                          className="h-[15px] w-[15px] relative object-cover min-h-[15px]"
                          alt=""
                          src="/16@2x.png"
                        />
                        <div className="relative inline-block min-w-[30px]">
                          Dana
                        </div>
                        <img
                          className="h-2 w-[15px] absolute !m-[0] top-[4px] left-[118px]"
                          alt=""
                          src="/vector-1.svg"
                        />
                      </div>
                      <div className="w-[235px] bg-white overflow-hidden flex flex-row items-start justify-start py-0 pr-[102px] pl-0 box-border relative">
                        <img
                          className="h-[15px] w-[15px] relative object-cover min-h-[15px]"
                          alt=""
                          src="/17@2x.png"
                        />
                        <div className="relative inline-block min-w-[25px]">
                          BCA
                        </div>
                        <img
                          className="h-2 w-[15px] absolute !m-[0] top-[4px] left-[118px]"
                          alt=""
                          src="/vector-1.svg"
                        />
                      </div>
                      <img
                        className="w-[15px] h-[15px] absolute !m-[0] top-[15px] left-[118px] overflow-hidden shrink-0 z-[1]"
                        alt=""
                        src="/teenyiconsdownsolid.svg"
                      />
                    </div>
                    <div className="flex-1 bg-white overflow-hidden flex flex-col items-start justify-start">
                      <div className="self-stretch bg-darkorange overflow-hidden flex flex-row items-start justify-start text-white">
                        <div className="relative inline-block min-w-[31px]">
                          Phone
                        </div>
                      </div>
                      <div className="self-stretch bg-white overflow-hidden flex flex-row items-start justify-start">
                        <div className="flex-1 relative">085974991595</div>
                      </div>
                      <div className="self-stretch bg-white overflow-hidden flex flex-row items-start justify-start">
                        <div className="relative inline-block min-w-[86px]">
                          085974991595
                        </div>
                      </div>
                      <div className="self-stretch bg-white overflow-hidden flex flex-row items-start justify-start">
                        <div className="relative inline-block min-w-[71px]">
                          7750710497
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div className="w-[449px] flex flex-row items-start justify-start py-0 px-[17px] box-border max-w-full">
              <div className="flex-1 flex flex-row items-start justify-start gap-[25px] max-w-full mq450:flex-wrap">
                <button className="cursor-pointer py-3.5 px-[57px] bg-darkorange flex-[0.4847] rounded-lg box-border flex flex-row items-start justify-start min-w-[127px] border-[1px] border-solid border-black mq450:flex-1">
                  <img
                    className="h-5 w-5 relative min-h-[20px]"
                    alt=""
                    src="/vector-9.svg"
                  />
                  <div className="flex-1 flex flex-col items-start justify-start pt-0.5 px-0 pb-0">
                    <div className="self-stretch relative text-base leading-[100%] font-semibold font-inter text-white text-left">{` Save `}</div>
                  </div>
                </button>
                <button className="cursor-pointer py-3.5 px-[15px] bg-[transparent] flex-1 rounded-lg box-border flex flex-row items-start justify-start min-w-[127px] whitespace-nowrap border-[1px] border-solid border-darkslategray-100 hover:bg-dimgray-200 hover:box-border hover:border-[1px] hover:border-solid hover:border-dimgray-100 mq450:flex-1">
                  <div className="flex-1 relative text-base leading-[100%] font-semibold font-inter text-darkslategray-100 text-center">
                    Share the result
                  </div>
                </button>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </div>
  );
};

export default BillInput;
