import { FunctionComponent } from "react";
import TextWrapper from "../components/TextWrapper";

const LandingPageSharePeople: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-row items-start justify-start leading-[normal] tracking-[normal]">
      <main className="flex-1 bg-gray-100 overflow-hidden flex flex-row items-start justify-start py-0 pr-0 pl-[53px] box-border gap-[77px] max-w-full text-left text-45xl text-white font-inter mq850:gap-[19px] mq1225:gap-[38px] mq1525:flex-wrap mq1525:pl-5 mq1525:pr-5 mq1525:pb-5 mq1525:box-border">
        <div className="w-[883px] flex flex-col items-start justify-start pt-[77px] px-0 pb-0 box-border min-w-[883px] max-w-full mq850:pt-[50px] mq850:box-border mq1225:min-w-full mq1525:flex-1">
          <div className="self-stretch flex flex-col items-start justify-start gap-[115px] max-w-full mq1225:gap-[57px] mq450:gap-[29px]">
            <img
              className="w-[454px] relative max-h-full object-contain max-w-full"
              loading="lazy"
              alt=""
              src="/logo-text--white-bg-transparan-1@2x1.png"
            />
            <div className="self-stretch flex flex-row items-start justify-end max-w-full">
              <div className="w-[816px] overflow-hidden shrink-0 flex flex-col items-start justify-start min-w-[762px] max-w-[816px] mq850:gap-[32px] mq850:max-w-full mq450:gap-[16px]">
                <div className="self-stretch overflow-hidden flex flex-col items-start justify-start gap-[24px] max-w-full">
                  <div className="self-stretch overflow-hidden flex flex-row items-start justify-start max-w-full">
                    <h1 className="m-0 flex-1 relative text-inherit leading-[100%] font-semibold font-inherit inline-block max-w-full mq850:text-32xl mq850:leading-[51px] mq450:text-19xl mq450:leading-[38px]">
                      Welcome to the Splitbill Revolution!
                    </h1>
                  </div>
                  <h1 className="m-0 self-stretch relative text-5xl leading-[150%] font-normal font-inherit text-gray-200 mq450:text-lgi mq450:leading-[29px]">
                    <p className="m-0">
                      We bring you unparalleled convenience in splitting bills.
                      With our motto, we invite you to enjoy a smart, swift, and
                      enjoyable experience in dividing expenses. Make bill
                      splitting more than just a task turn it into joyful
                      moments with us.
                    </p>
                    <p className="m-0">&nbsp;</p>
                    <p className="m-0">-Bill Master</p>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 bg-gray-300 box-border overflow-hidden flex flex-row items-end justify-start py-[148px] pr-[71px] pl-[72px] min-w-[590px] min-h-[960px] max-w-full border-[1px] border-solid border-black mq850:min-w-full mq1225:py-24 mq1225:pr-[35px] mq1225:pl-9 mq1225:box-border mq1525:min-h-[auto] mq450:pt-[62px] mq450:pb-[62px] mq450:box-border">
          <TextWrapper />
        </div>
      </main>
    </div>
  );
};

export default LandingPageSharePeople;
