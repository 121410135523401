import React, { useState, useEffect, ChangeEvent, FormEvent, FocusEvent, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCurrentDate } from '../utils/utils';

const DEBUG = false;
const API_URL = 'https://api.splitbill.io';

interface Buyer {
    name: string;
    qty: number;
}

interface Item {
    name: string;
    qty: number;
    subtotal: number;
    buyers: Buyer[];
}

interface ApiItem {
    name: string;
    qty: number;
    subtotal: number;
}

interface PaymentMethod {
    name: string;
    account_number: string;
}

interface Order {
    merchant: string;
    date: string;
    deliveryFee: number;
    otherFees: number;
    totalDiscount: number;
    totalTax: number;
    items: Item[];
    paymentMethods: PaymentMethod[];
}

const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (e.target.value === '0') {
        e.target.value = '';
    }
};

const handleBlur = (e: FocusEvent<HTMLInputElement>, setOrder: React.Dispatch<React.SetStateAction<Order>> | React.Dispatch<React.SetStateAction<Item>> | React.Dispatch<React.SetStateAction<Buyer>>) => {
    const { name, value } = e.target;
    if (value === '') {
        setOrder((prev: any) => ({ ...prev, [name]: 0 }));
    }
};

const OrderForm: React.FC = () => {
    const [order, setOrder] = useState<Order>(() => {
        const storedOrder = localStorage.getItem('order');
        return storedOrder ? JSON.parse(storedOrder) : {
            merchant: '',
            date: getCurrentDate(),
            deliveryFee: 0,
            otherFees: 0,
            totalDiscount: 0,
            totalTax: 0,
            items: [],
            paymentMethods: []
        };
    });

    const [item, setItem] = useState<Item>({
        name: '',
        qty: 0,
        subtotal: 0,
        buyers: [],
    });

    const [buyer, setBuyer] = useState<Buyer>({
        name: '',
        qty: 0,
    });

    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>({
        name: '',
        account_number: ''
    });

    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const storedApiResponse = localStorage.getItem('apiResponse');
        const storedPaymentMethods = localStorage.getItem('savedPaymentMethods');

        if (storedApiResponse) {
            const apiResponse = JSON.parse(storedApiResponse);

            // Debug log untuk memeriksa struktur data
            if (DEBUG) console.log('API Response:', apiResponse);

            if (apiResponse.data && apiResponse.data.items && Array.isArray(apiResponse.data.items)) {
                const items: Item[] = apiResponse.data.items.map((item: ApiItem) => ({
                    name: item.name,
                    qty: item.qty,
                    subtotal: item.subtotal,
                    buyers: []
                }));

                if (DEBUG) console.log('Mapped Items:', items); // Debug log

                setOrder(prevOrder => ({
                    ...prevOrder,
                    items: items
                }));
            } else {
                if (DEBUG) console.error('Invalid items data in API response', apiResponse);
            }
        }

        if (storedPaymentMethods) {
            const paymentMethods = JSON.parse(storedPaymentMethods);
            setOrder(prevOrder => ({
                ...prevOrder,
                paymentMethods: paymentMethods
            }));
        }
    }, []);


    useEffect(() => {
        localStorage.setItem('order', JSON.stringify(order));
    }, [order]);

    const handleOrderChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value, type } = e.target;
        setOrder({
            ...order,
            [name]: type === 'number' ? parseFloat(value) : value,
        });
    };

    const handleItemChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const { name, value, type } = e.target;
        const newItems = [...order.items];
        newItems[index] = {
            ...newItems[index],
            [name]: type === 'number' ? parseFloat(value) : value,
        };
        setOrder({ ...order, items: newItems });
    };

    const handleBuyerChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value, type } = e.target;
        setBuyer({
            ...buyer,
            [name]: type === 'number' ? parseFloat(value) : value,
        });
    };

    const handleBuyerAdd = (itemIndex: number) => {
        const updatedItems = [...order.items];
        const updatedItem = { ...updatedItems[itemIndex] };
        const assignedQty = updatedItem.buyers.reduce((acc, buyer) => acc + buyer.qty, 0);
        const remainingQty = updatedItem.qty - assignedQty;

        if (!remainingQty) {
            return toast.error('Total quantity of the item has reached 0.');
        } else if (buyer.qty > remainingQty) {
            return toast.error('Total quantity assigned to buyers cannot exceed item quantity.');
        } else if (!buyer.name.length) {
            return toast.error("Please enter the buyer's name.")
        } else if (!buyer.qty) {
            return toast.error("Please enter the quantity of the item purchased by the buyer.")
        }

        updatedItem.buyers.push({ name: buyer.name, qty: buyer.qty });
        updatedItems[itemIndex] = updatedItem;

        setOrder({ ...order, items: updatedItems });
        setBuyer({ name: '', qty: 0 });
    };

    const handlePaymentMethodChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const { name, value } = e.target;
        const newPaymentMethods = [...order.paymentMethods];
        newPaymentMethods[index] = {
            ...newPaymentMethods[index],
            [name]: value,
        };
        setOrder({ ...order, paymentMethods: newPaymentMethods });
    };

    const handlePaymentMethodAdd = () => {
        setOrder({
            ...order,
            paymentMethods: [...order.paymentMethods, { ...paymentMethod }]
        });
        setPaymentMethod({ name: '', account_number: '' });
    };

    const handlePaymentMethodDelete = (index: number) => {
        const newMethods = [...order.paymentMethods];
        newMethods.splice(index, 1);
        setOrder({ ...order, paymentMethods: newMethods });
    }

    const handleBuyerDelete = (itemIndex: number, buyerIndex: number) => {
        const updatedItems = [...order.items];
        const updatedItem = { ...updatedItems[itemIndex] };
        updatedItem.buyers.splice(buyerIndex, 1);
        updatedItems[itemIndex] = updatedItem;
        setOrder({ ...order, items: updatedItems });
    };

    const handleItemDelete = (index: number) => {
        const newItems = [...order.items];
        newItems.splice(index, 1);
        setOrder({ ...order, items: newItems });
    };

    const generateRandomUserId = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const userIdLength = 10;
        let userId = '';

        for (let i = 0; i < userIdLength; i++) {
            userId += characters.charAt(Math.floor(Math.random() * characters.length));
        }

        return userId;
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (DEBUG) console.log(order);

        try {
            const userId = localStorage.getItem('userId') ?? generateRandomUserId();

            localStorage.setItem('savedPaymentMethods', JSON.stringify(order.paymentMethods));

            const response = await fetch(`${API_URL}/bill`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...order, userId }),
            });

            if (!response.ok) {
                throw new Error('Failed to submit form');
            }

            const responseData = await response.json();
            const billId = responseData.data.id; // Mengambil ID dari respons

            localStorage.removeItem('order');
            localStorage.removeItem('apiResponse');

            navigate(`/result/${billId}`);
        } catch (error) {
            if (DEBUG) console.error('Error submitting form:', error);
            toast.error("Sorry, there's a problem when submitting. Please try again.");
        }
    };


    const addItem = () => {
        const newItem: Item = { name: item.name, qty: item.qty, subtotal: item.subtotal, buyers: [] };
        if (!item.name.length || !item.qty)
            return toast.error('Please fill in the item details.');

        setOrder({ ...order, items: [...order.items, newItem] });
        setItem({ name: '', qty: 0, subtotal: 0, buyers: [] });

        if (refInputFocus.current)
            refInputFocus.current.focus();

        return true;
    };

    const refInputFocus = useRef<HTMLInputElement>(null);

    const pages = [
        <div key="page1" className="p-4 border border-gray-700 rounded-md bg-zinc-700">
            <div className="space-y-5">
                <label className="text-xl rounded-lg font-semibold flex justify-center items-center px-4 py-2">BILL DETAILS</label>
                <hr className="divider" />
                <div className="space-y-2">
                    <label className="block text-lg font-medium">Merchant*</label>
                    <input
                        autoFocus={true}
                        type="text"
                        name="merchant"
                        value={order.merchant}
                        onChange={handleOrderChange}
                        className="w-full rounded-md bg-zinc-800 border border-gray-700 font-inter min-h-10 input-style text-white"
                    />
                </div>
                <div className="space-y-2">
                    <label className="block text-lg font-medium">Date*</label>
                    <input
                        type="date"
                        name="date"
                        value={order.date}
                        onChange={handleOrderChange}
                        className="w-full rounded-md bg-zinc-800 border border-gray-700 text-white font-inter min-h-10 input-style"
                    />
                </div>
                <div className="space-y-2">
                    <label className="block text-lg font-medium">Delivery Fee</label>
                    <input
                        type="number"
                        name="deliveryFee"
                        min={0}
                        value={order.deliveryFee}
                        onChange={handleOrderChange}
                        onFocus={handleFocus}
                        onBlur={(e) => handleBlur(e, setOrder)}
                        className="w-full rounded-md bg-zinc-800 border border-gray-700 text-white font-inter min-h-10 input-style "
                    />
                </div>
                <div className="space-y-2">
                    <label className="block text-lg font-medium">Other Fees</label>
                    <input
                        type="number"
                        name="otherFees"
                        min={0}
                        value={order.otherFees}
                        onChange={handleOrderChange}
                        onFocus={handleFocus}
                        onBlur={(e) => handleBlur(e, setOrder)}
                        className="w-full rounded-md bg-zinc-800 border border-gray-700 text-white font-inter min-h-10 input-style"
                    />
                </div>
                <div className="space-y-2">
                    <label className="block text-lg font-medium">Discount</label>
                    <input
                        type="number"
                        name="totalDiscount"
                        min={0}
                        value={order.totalDiscount}
                        onChange={handleOrderChange}
                        onFocus={handleFocus}
                        onBlur={(e) => handleBlur(e, setOrder)}
                        className="w-full rounded-md bg-zinc-800 border border-gray-700 text-white font-inter min-h-10 input-style"
                    />
                </div>
                <div className="space-y-2">
                    <label className="block text-lg font-medium">Tax</label>
                    <input
                        type="number"
                        name="totalTax"
                        min={0}
                        value={order.totalTax}
                        onChange={handleOrderChange}
                        onFocus={handleFocus}
                        onBlur={(e) => handleBlur(e, setOrder)}
                        onKeyDown={(e) => e.key === 'Enter' && handleNext()}
                        className="w-full rounded-md bg-zinc-800 border border-gray-700 text-white font-inter min-h-10 input-style"
                    />
                </div>
            </div>
        </div>,
        <div key="page2" className="p-4 border border-gray-700 rounded-md bg-zinc-700">
            <div className="space-y-5">
                <label className="text-xl rounded-lg font-semibold flex justify-center items-center px-4 py-2">ITEM DETAILS</label>
                <hr className="divider" />
                <div className="space-y-2">
                    <label className="block text-lg font-medium">Name*</label>
                    <input
                        ref={refInputFocus}
                        autoFocus={true}
                        type="text"
                        name="name"
                        value={item.name}
                        onChange={(e) => setItem({ ...item, name: e.target.value })}
                        onKeyDown={(e) => e.key === 'Enter' && (e.currentTarget.value && addItem() || handleNext())} // if empty, open next page
                        className="w-full rounded-md bg-zinc-800 border border-gray-700 text-white font-inter min-h-10 input-style"
                    />
                </div>
                <div className="space-y-2">
                    <label className="block text-lg font-medium">Quantity*</label>
                    <input
                        type="number"
                        name="qty"
                        min={0}
                        value={item.qty}
                        onFocus={handleFocus}
                        onChange={(e) => setItem({ ...item, qty: parseFloat(e.target.value) })}
                        onBlur={(e) => handleBlur(e, setItem)}
                        onKeyDown={(e) => e.key === 'Enter' && (e.currentTarget.value && addItem() || handleNext())}
                        className="w-full rounded-md bg-zinc-800 border border-gray-700 text-white font-inter min-h-10 input-style"
                    />
                </div>
                <div className="space-y-2">
                    <label className="block text-lg font-medium">Subtotal</label>
                    <input
                        type="number"
                        name="subtotal"
                        min={0}
                        value={item.subtotal}
                        onFocus={handleFocus}
                        onChange={(e) => setItem({ ...item, subtotal: parseFloat(e.target.value) })}
                        onBlur={(e) => handleBlur(e, setItem)}
                        onKeyDown={(e) => e.key === 'Enter' && (e.currentTarget.value && addItem() || handleNext())}
                        className="w-full rounded-md bg-zinc-800 border border-gray-700 text-white font-inter min-h-10 input-style"
                    />
                </div>
                <div className="flex justify-between">
                    <button
                        type="button"
                        onClick={addItem}
                        className="px-4 py-2 bg-blue-600 text-white rounded-md cursor-pointer"
                    >
                        Add Item
                    </button>
                </div>
                {order.items.length > 0 &&
                    <div className="mt-4 space-y-4">
                        {order.items.map((item, index) => (
                            <div key={index} className="p-4 border border-gray-700 rounded-md bg-zinc-800">
                                <h4 className="text-lg font-medium mt-0">{item.name}</h4>
                                <p className="text-sm text-white">Quantity: {item.qty}</p>
                                <p className="text-sm text-white">Subtotal: {item.subtotal}</p>
                                <button
                                    type="button"
                                    onClick={() => handleItemDelete(index)}
                                    className="px-4 py-2 bg-red-600 text-white rounded-md mt-2 cursor-pointer"
                                >
                                    Delete Item
                                </button>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </div>,
        <div key="page3" className="p-4 border border-gray-700 rounded-md bg-zinc-700">
            <div className="space-y-5">
                <label className="text-xl rounded-lg font-semibold flex justify-center items-center px-4 py-2">BUYER DETAILS</label>
                <hr className="divider" />
                {order.items.map((item, itemIndex) => {
                    // Menghitung sisa qtyLeft untuk setiap item
                    const qtyLeft = item.qty - item.buyers.reduce((acc, buyer) => acc + buyer.qty, 0);

                    return (
                        <div key={itemIndex} className="mb-4 border border-solid border-zinc-500 rounded-md p-3">
                            <div className="space-y-2">
                                <label className="block text-lg font-medium">Item Name</label>
                                <input
                                    autoFocus={itemIndex === 0}
                                    type="text"
                                    name="name"
                                    value={item.name}
                                    disabled={true}
                                    className="w-full rounded-md bg-zinc-800 border border-gray-700 text-white font-inter min-h-10 input-style"
                                />
                            </div>
                            <div className="space-y-2">
                                <label className="block text-lg font-medium mt-4">Buyer Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={buyer.name}
                                    onChange={handleBuyerChange}
                                    className="w-full rounded-md bg-zinc-800 border border-gray-700 text-white font-inter min-h-10 input-style"
                                />
                            </div>
                            <div className="space-y-2">
                                <label className="block text-lg font-medium mt-4">Quantity ({qtyLeft} left)</label>
                                <div className="flex items-center">
                                    <input
                                        type="number"
                                        name="qty"
                                        min={0}
                                        value={buyer.qty}
                                        onBlur={(e) => handleBlur(e, setBuyer)}
                                        onChange={handleBuyerChange}
                                        onFocus={handleFocus}
                                        // TODO:
                                        // Uncomment after each buyer is linked to each of their items
                                        // onKeyDown={(e) => e.key === 'Enter' && (e.currentTarget.value && handleBuyerAdd(itemIndex) || handleNext())} // Add buyer after quantity
                                        onKeyDown={(e) => e.key === 'Enter' && e.currentTarget.value && handleBuyerAdd(itemIndex)} // Add buyer after quantity
                                        className="w-full rounded-md bg-zinc-800 border border-gray-700 text-white font-inter min-h-10 input-style"
                                    />
                                </div>
                            </div>
                            <div className="flex justify-between">
                                <button
                                    type="button"
                                    onClick={() => handleBuyerAdd(itemIndex)}
                                    className="px-4 py-2 bg-blue-600 text-white rounded-md mt-4 mb-0 cursor-pointer"
                                >
                                    Add Buyer
                                </button>
                            </div>
                            {item.buyers.length > 0 &&
                                <div className="mt-4 space-y-4">
                                    {item.buyers.map((buyer, buyerIndex) => (
                                        <div key={buyerIndex} className="p-4 border border-gray-700 rounded-md bg-zinc-800">
                                            <h4 className="text-lg font-medium mt-0">{buyer.name}</h4>
                                            <p className="text-sm text-white">Quantity: {buyer.qty}</p>
                                            <button
                                                type="button"
                                                onClick={() => handleBuyerDelete(itemIndex, buyerIndex)}
                                                className="px-4 py-2 bg-red-600 text-white rounded-md mt-2 cursor-pointer"
                                            >
                                                Delete Buyer
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    );
                })}

            </div>
        </div>,
        <div key="page4" className="p-4 border border-gray-700 rounded-md bg-zinc-700">
            <div className="space-y-5">
                <label className="text-xl rounded-lg font-semibold flex justify-center items-center px-4 py-2">PAYMENT DETAILS</label>
                <hr className="divider" />
                <div className="space-y-2">
                    <label className="block text-lg font-medium">Payment Method Name</label>
                    <input
                        type="text"
                        name="name"
                        value={paymentMethod.name}
                        onChange={(e) => setPaymentMethod({ ...paymentMethod, name: e.target.value })}
                        className="w-full rounded-md bg-zinc-800 border border-gray-700 text-white font-inter min-h-10 input-style"
                    />
                </div>
                <div className="space-y-2">
                    <label className="block text-lg font-medium">Account Number</label>
                    <input
                        type="text"
                        name="account_number"
                        value={paymentMethod.account_number}
                        onChange={(e) => setPaymentMethod({ ...paymentMethod, account_number: e.target.value })}
                        onKeyDown={(e) => e.key === 'Enter' && e.currentTarget.value && handlePaymentMethodAdd()} // Add payment method after account number
                        className="w-full rounded-md bg-zinc-800 border border-gray-700 text-white font-inter min-h-10 input-style"
                    />
                </div>
                <div className="flex justify-between">
                    <button
                        type="button"
                        onClick={handlePaymentMethodAdd}
                        className="px-4 py-2 bg-blue-600 text-white rounded-md cursor-pointer"
                    >
                        Add Payment Method
                    </button>
                </div>
                {order.paymentMethods.length > 0 &&
                    <div className="mt-4 space-y-4">
                        {order.paymentMethods.map((method, methodIndex) => (
                            <div key={methodIndex} className="p-4 border border-gray-700 rounded-md bg-zinc-800">
                                <h4 className="text-lg font-medium mt-0">{method.name}</h4>
                                <p className="text-sm text-white">Account Number: {method.account_number}</p>
                                <button
                                    type="button"
                                    onClick={() => handlePaymentMethodDelete(methodIndex)}
                                    className="px-4 py-2 bg-red-600 text-white rounded-md mt-2 cursor-pointer"
                                >
                                    Delete Payment Method
                                </button>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </div>
    ];

    const pageValidation = (page: number) => {
        if (page === 0) {
            // Validasi halaman BILL DETAILS
            if (order.merchant === '' || order.date === '') {
                toast.error('Please fill in all fields in this page before proceeding.');
                return false;
            }
        } else if (page === 1) {
            // Validasi halaman ITEM DETAILS
            if (order.items.length === 0 || order.items.some(item => item.name === '' || item.qty === 0)) {
                toast.error('Please add at least one item in this page before proceeding.');
                return false;
            }
        } else if (page === 2) {
            // Validasi halaman BUYER DETAILS
            if (order.items.length === 0 || order.items.some(item => item.buyers.length === 0) || order.items.some(item => item.qty !== item.buyers.reduce((acc, buyer) => acc + buyer.qty, 0))) {
                toast.error('Please assign all item quantities to buyers in this page before proceeding.');
                return false;
            }
        }

        return true;
    }

    const handleNext = () => {
        if (currentPage < pages.length - 1) {
            const isValid = pageValidation(currentPage);
            if (!isValid) return false;

            setCurrentPage(prevPage => prevPage + 1);
            return true;
        }
    };

    const handlePrev = () => {
        if (currentPage > 0) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const handleGoToPage = (targetPage: number) => {
        for (let page = 0; page < targetPage; page++) {
            const isValid = pageValidation(page);
            if (!isValid) return setCurrentPage(page);
        }

        setCurrentPage(targetPage);
    }

    const renderPageContent = () => {
        return pages[currentPage];
    };

    const renderTimeline = () => {
        return (
            <div className="flex justify-between mb-4">
                {pages.map((_, index) => (
                    <div
                        key={index}
                        onClick={() => handleGoToPage(index)}
                        className={`w-1/5 h-2 ${index <= currentPage ? 'bg-blue-600' : 'bg-gray-700'} transition-all duration-500 cursor-pointer`}
                    ></div>
                ))}
            </div>
        );
    };

    return (
        <div className="min-h-screen bg-zinc-900 flex justify-center items-center font-inter">
            <div className="relative max-w-4xl mx-auto p-6">
                <div className="absolute inset-0 opacity-70 rounded-lg blur-lg running-color-bg"></div>
                <div className="relative bg-zinc-800 text-white rounded-lg shadow-lg p-6 border-2 border-transparent z-10">
                    <div className="flex justify-center">
                        <img
                            className="w-[454px] h-[104px] relative object-contain max-w-full"
                            loading="lazy"
                            alt="Logo Splitbill.io"
                            src="/logo-text--white-bg-transparan-1@2x1.png"
                        />
                    </div>
                    <div className="max-w-xl mx-auto p-4 space-y-4">
                        <ToastContainer />
                        <form onSubmit={handleSubmit}>
                            {renderTimeline()}
                            <motion.div
                                key={currentPage}
                                initial={{ opacity: 0, x: 100 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: -100 }}
                                transition={{ duration: 0.5 }}
                            >
                                {renderPageContent()}
                            </motion.div>
                            <div className="flex justify-between mt-4">
                                <div>
                                    {currentPage !== 0 && (
                                        <button
                                            type="button"
                                            onClick={handlePrev}
                                            className="px-6 py-3 bg-gray-600 text-white rounded-md cursor-pointer"
                                        >
                                            Previous
                                        </button>
                                    )}
                                </div>
                                <div>
                                    {currentPage !== pages.length - 1 && (
                                        <button
                                            type="button"
                                            onClick={handleNext}
                                            className="px-6 py-3 bg-blue-600 text-white rounded-md cursor-pointer"
                                        >
                                            Next
                                        </button>
                                    )}
                                    {currentPage === pages.length - 1 && (
                                        <button
                                            type="submit"
                                            className="px-6 py-3 bg-green-600 text-white rounded-md cursor-pointer">
                                            Submit
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderForm;
