// SelectionPage.tsx
import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import ClipLoader from 'react-spinners/ClipLoader';
import 'react-toastify/dist/ReactToastify.css';

const API_URL = 'https://api.splitbill.io';

const generateRandomUserId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const userIdLength = 10;
    let userId = '';

    for (let i = 0; i < userIdLength; i++) {
        userId += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return userId;
};

const checkVersion = async () => {
    try {
        const response = await fetch(`${API_URL}/version`);
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const responseText = await response.text();
        const storedVersion = localStorage.getItem('appVersion');
        const isDataEmpty = !localStorage.getItem('order') && !localStorage.getItem('apiResponse');

        if (storedVersion !== responseText || isDataEmpty) {
            localStorage.removeItem('order');
            localStorage.removeItem('apiResponse');
            toast.success('Splitbill.io just got updated!');
        }

        // Update stored version
        localStorage.setItem('appVersion', responseText);

    } catch (error) {
        toast.error('Error checking version.');
    }
};

const SelectionPage: React.FC = () => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setSelectedFile(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/*': []
        }
    });

    const handleFileUpload = async () => {
        if (!selectedFile) {
            toast.error('Please select a file first.');
            return;
        }

        setIsLoading(true);

        const userId = generateRandomUserId();
        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('document', selectedFile);

        try {
            const response = await fetch(`${API_URL}/bill/analyze`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to upload file');
            }

            const responseData = await response.json();
            console.log(responseData);

            // Reset localStorage and save new response data
            localStorage.removeItem('apiResponse'); // Reset local storage
            localStorage.setItem('apiResponse', JSON.stringify(responseData));

            toast.success('File uploaded successfully!');
            navigate('/order-form');
        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error('Error uploading file.');
        } finally {
            setIsLoading(false);
        }
    };

    const goToOrderForm = () => {
        navigate('/order-form');
    };

    // Check version when component mounts
    useEffect(() => {
        checkVersion();
    }, []);

    return (
        <div className="min-h-screen bg-zinc-900 flex justify-center items-center font-inter">
            <div className="relative max-w-4xl mx-auto p-6">
                <div className="absolute inset-0 opacity-70 rounded-lg blur-lg running-color-bg"></div>
                <div className="relative bg-zinc-800 text-white rounded-lg shadow-lg p-6 border-2 border-transparent z-10">
                    <div className="flex justify-center mb-6">
                        <img
                            className="w-[454px] h-[104px] relative object-contain max-w-full"
                            loading="lazy"
                            alt="Logo Splitbill.io"
                            src="/logo-text--white-bg-transparan-1@2x1.png"
                        />
                    </div>
                    <div className="max-w-xl mx-auto p-4">
                        <div
                            {...getRootProps()}
                            className={`border-2 border-dashed border-white rounded-md p-4 text-center ${isDragActive ? 'border-blue-600' : 'border-gray-600'
                                }`}
                        >
                            <input {...getInputProps()} />
                            {selectedFile ? (
                                <p>Selected file: {selectedFile.name}</p>
                            ) : (
                                <p>
                                    Drag & drop a file here, or click to select a file
                                </p>
                            )}
                        </div>
                        <button
                            type="button"
                            onClick={handleFileUpload}
                            className="w-full px-4 py-2 bg-blue-600 text-white rounded-md mt-4 h-16 text-lg font-semibold cursor-pointer"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <ClipLoader color="#ffffff" size={24} />
                            ) : (
                                'Upload Now'
                            )}
                        </button>
                        <hr className="dividerSelectionPage my-4" />
                        <button
                            type="button"
                            onClick={goToOrderForm}
                            className="w-full px-4 py-2 bg-green-600 text-white rounded-md h-10 cursor-pointer"
                        >
                            Manual Input
                        </button>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default SelectionPage;
